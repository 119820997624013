import React from "react"
import { Container, Row, Col } from 'react-awesome-styled-grid'

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomeBGSEction from "../components/homesection"

const IndexPage = () => (
  <Layout>
    <SEO title="Training and custom web and mobile application development" />
    <div className='home-section'>
    <Container>
      <Row align='center'>
        <Col xs={4} sm={4} lg={6} noGutter >
           <h1 style={{
             maxWidth: `400px`
           }}>Passionate to become a <span style={{
                color: `#5d66f1`
              }}> developer?</span>
            </h1>
           <p>Be committed, learn real-time, work with professionals.</p>

           <p style={{
            maxWidth: `600px`
           }}>
           Codezene believes learning is a process and offers professional training in Front-End and Back-End development by adopting open source technologies resulting to gain Full-Stack programming skills.
           </p>

           <p style={{
            fontFamily: `'Pacifico', cursive`,
            fontSize: `30px`,
            fontWeight: `400`
           }}>Coming Soon!</p>

        </Col>
        <Col xs={4} sm={4} lg={6} noGutter>
          <HomeBGSEction />
        </Col>
      </Row>
    </Container>
    </div>
  </Layout>
)

export default IndexPage
